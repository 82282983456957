import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from 'components/layouts/Layout';

function RefundAndCancellationPolicy() {
  const data = useStaticQuery(
    graphql`
      query {
        login_bg: file(relativePath: { eq: "bg-hero-darkened.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    `
  );
  const bg_login = getImage(data.login_bg);
  const loginBg = convertToBgImage(bg_login);

  return (
    <Layout pageTitle="Contact Us">
      <BackgroundImage Tag="section" className="bg-bottom-footer" {...loginBg}>
        <section className="section-secondary relative w-full h-full">
          <div className="max-w-screen-2xl mx-auto grid grid-cols-12 pt-10 xl:px-20 px-5 panel-contactus">
            <div className="col-span-12 pt-10 pb-5 xl:mb-20 mb-10 xl:mx-5 mt-5 xl:px-10 px-5 bg-white rounded-xl text-grey">
              <div className="secondary-page-content">
                <h1 className="text-left font-bold font-rokkit xl:mb-10 mb-6 xl:text-4xl text-3xl text-light-grey">Contact US</h1>
                <form method="POST" action="https://usebasin.com/f/adf8e7200ee2">
                  <div className="xl:grid grid-cols-12 gap-8">
                    <div className="xl:col-span-12 col-span-12">
                      <label htmlFor="Name">Name *</label>
                      <input
                        id="Name"
                        type="text"
                        name="name"
                        className="w-full border-b-2 h-12 px-0 mb-3 focus:outline-none focus:shadow-none pb-1 bg-transparent"
                        required
                      />
                    </div>
                    <div className="xl:col-span-12 col-span-12">
                      <label htmlFor="phone-number">Phone *</label>
                      <input
                        id="phone-number"
                        type="tel"
                        name="phone"
                        className="w-full border-b-2 h-12 px-0 mb-3 focus:outline-none focus:shadow-none pb-1 bg-transparent"
                        required
                      />
                    </div>
                  </div>
                  <br />
                  <label htmlFor="email-add">E-Mail Address *</label>
                  <input
                    id="email-add"
                    type="email"
                    name="email"
                    className="w-full border-b-2 h-12 px-0 mb-3 focus:outline-none focus:shadow-none pb-1 bg-transparent"
                    required
                  />
                  <br />
                  <br />
                  <label htmlFor="message">Message *</label>
                  <br />
                  <br />
                  <textarea
                    rows="5"
                    name="message"
                    id="message"
                    className="rounded-md border-black border w-full px-5 mb-3 focus:outline-none focus:shadow-none pb-1 bg-transparent textarea-contact"
                    required
                  />
                  <button className="button-dark font-bold font-opensans rounded-md text-lg h-14  w-full mt-5 transition-all duration-500">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </BackgroundImage>
    </Layout>
  );
}

export default RefundAndCancellationPolicy;
